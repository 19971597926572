import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import BlogITvsOTImage from '../images/BlogITvsOT.png';
import { useTranslation } from 'react-i18next';
import './BlogImg.css'; // Import the CSS file

const BlogITOTConvergence = () => {
  const [t, i18n] = useTranslation('global');

  // Determine if the current language is RTL
  const isRTL = i18n.language === 'he';

  useEffect(() => {
    // Ensure the <html> element's direction matches the language
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  }, [isRTL]);

  return (
    <>
      <div>
        <NavBar />
      </div>
      <main
        style={{
          margin: '180px auto',
          fontFamily: 'Arial, sans-serif',
          lineHeight: '1.8',
          textAlign: isRTL ? 'right' : 'left', // Dynamic text alignment
          direction: isRTL ? 'rtl' : 'ltr', // Dynamic text direction
          maxWidth: '1500px',
          padding: '0 20px',
        }}
      >
        <div
          style={{
            width: '100%',
            marginBottom: '30px',
            display: 'flex', // Use flexbox for centering
            justifyContent: 'center', // Horizontally center the image
          }}
        >
          <img
            src={BlogITvsOTImage}
            alt={t("blogs.blogITvsOT.header1")}
             className="responsive-image"
            style={{
              width: '50%',
              height: '20rem',
              borderRadius: '8px',
            }}
          />
        </div>
        <article>
          <h1
            style={{
              color: '#68cf24',
              fontSize: '3rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
              textAlign: isRTL ? 'right' : 'left', // Align header dynamically
            }}
          >
            {t("blogs.blogITvsOT.header1")}
          </h1>
          <p
            style={{
              fontSize: '1.5rem',
              textAlign: isRTL ? 'right' : 'left', // Align text dynamically
            }}
          >
            {t("blogs.blogITvsOT.IntroParagraph")}
          </p>

          <h2
            style={{
              color: '#68cf24',
              fontSize: '2.5rem',
              marginTop: '2rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
              textAlign: isRTL ? 'right' : 'left', // Align header dynamically
            }}
          >
            {t("blogs.blogITvsOT.header2")}
          </h2>
          <ul
            style={{
              fontSize: '1.5rem',
              listStyle: 'none',
              padding: '0',
              textAlign: isRTL ? 'right' : 'left', // Align list dynamically
            }}
          >
            <li>{t("blogs.blogITvsOT.feature1")}</li>
            <li>{t("blogs.blogITvsOT.feature2")}</li>
            <li>{t("blogs.blogITvsOT.feature3")}</li>
            <li>{t("blogs.blogITvsOT.feature4")}</li>
            <li>{t("blogs.blogITvsOT.feature5")}</li>
          </ul>

          <h2
            style={{
              color: '#68cf24',
              fontSize: '2.5rem',
              marginTop: '2rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
              textAlign: isRTL ? 'right' : 'left', // Align header dynamically
            }}
          >
            {t("blogs.blogITvsOT.header3")}
          </h2>
          <p
            style={{
              fontSize: '1.5rem',
              textAlign: isRTL ? 'right' : 'left', // Align text dynamically
            }}
          >
            {t("blogs.blogITvsOT.benefitsIntro")}
          </p>
          <ol
            style={{
              fontSize: '1.5rem',
              listStylePosition: isRTL ? 'inside' : 'outside',
              textAlign: isRTL ? 'right' : 'left', // Align list dynamically
            }}
          >
            <li>{t("blogs.blogITvsOT.benefit1")}</li>
            <li>{t("blogs.blogITvsOT.benefit2")}</li>
            <li>{t("blogs.blogITvsOT.benefit3")}</li>
            <li>{t("blogs.blogITvsOT.benefit4")}</li>
            <li>{t("blogs.blogITvsOT.benefit5")}</li>
          </ol>

          <h2
            style={{
              color: '#68cf24',
              fontSize: '2.5rem',
              marginTop: '2rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
              textAlign: isRTL ? 'right' : 'left', // Align header dynamically
            }}
          >
            {t("blogs.blogITvsOT.header4")}
          </h2>
          <p
            style={{
              fontSize: '1.5rem',
              textAlign: isRTL ? 'right' : 'left', // Align text dynamically
            }}
          >
            {t("blogs.blogITvsOT.conclusionParagraph")}
          </p>
        </article>
      </main>
      <Footer />
    </>
  );
};

export default BlogITOTConvergence;
