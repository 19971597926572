

import Barkan from '../images/clients/Barkan.png';
import Mekorot from '../images/clients/Mekorot.png';
import temo from '../images/clients/temo.png';
import Avgol from '../images/clients/Avgol.png';
import PCB from '../images/clients/PCB.png';
import Moog from '../images/clients/Moog.png';
import Nilit from '../images/clients/Nilit.png';
import sap from '../images/clients/sap.png';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

// Define styles for the client images
const clientImage = {
    height: '10rem',
    width: '100%',
    mixBlendMode: 'colorBurn'
};

// Blog subjects data
const blogSubjects = [
    {
        title: "How to Start a Blog",
        description: "A step-by-step guide on starting your own blog, from choosing a niche to publishing your first post."
    },
    {
        title: "Top 10 Blogging Platforms",
        description: "An overview of the best blogging platforms available, comparing their features, pros, and cons."
    },
    {
        title: "SEO Tips for Bloggers",
        description: "Essential SEO strategies to help your blog rank higher on search engines and attract more traffic."
    },
    {
        title: "Content Marketing Strategies",
        description: "Effective content marketing tactics to grow your blog audience and engage readers."
    },
    {
        title: "Monetizing Your Blog",
        description: "Various methods to make money from your blog, including ads, affiliate marketing, and sponsored posts."
    },
    {
        title: "Social Media for Bloggers",
        description: "Tips on using social media platforms to promote your blog and increase your reach."
    },
    {
        title: "Creating Engaging Content",
        description: "Ideas and techniques for writing blog posts that captivate and retain your audience."
    },
    {
        title: "Design Tips for Bloggers",
        description: "Advice on designing an aesthetically pleasing and user-friendly blog layout."
    },
    {
        title: "Blogging Tools and Resources",
        description: "A list of useful tools and resources that can help streamline your blogging process."
    },
    {
        title: "Building an Email List",
        description: "Strategies for growing an email list to keep your readers informed and engaged."
    }
];

const Clients = () => {
    const [t, i18n] = useTranslation('global');
    const [isLoading, setIsLoading] = useState(true);
    const initialLanguage = localStorage.getItem('language') || 'en';
    const initialIsRTL = initialLanguage === 'he';
    const [isRTL, setIsRTL] = useState(initialIsRTL);

    useEffect(() => {
        const lang = isRTL ? 'he' : 'en';
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);

        if (isRTL) {
            document.body.classList.remove('dir-ltr');
            document.body.classList.add('dir-rtl');
        } else {
            document.body.classList.remove('dir-rtl');
            document.body.classList.add('dir-ltr');
        }

        setIsLoading(false);
    }, [isRTL, i18n]);

    return (
        <div className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-custom-color2 uppercase font-bold">{t("Clients.header")}</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-green-900'></div>
                    </div>
                </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-4 lg:grid-cols-4 gap-4">
                        {/* Client Images */}
                        <div style={{...clientImage, paddingBottom: '2rem'}} className="overflow-hidden flex justify-center transition-all hover:opacity-100 w-1/6">
                            <img src={Barkan} alt="client" />
                        </div>
                        <div style={{...clientImage, paddingBottom: '2rem'}} className="overflow-hidden p-3 flex justify-center transition-all hover:opacity-100">
                            <img src={temo} alt="client" />
                        </div>
                        <div style={{...clientImage, paddingBottom: '2rem'}} className="overflow-hidden p-3 flex justify-center transition-all hover:opacity-100">
                            <img src={Avgol} alt="client" />
                        </div>
                        <div style={{...clientImage, paddingBottom: '2rem'}} className="overflow-hidden p-3 flex justify-center transition-all hover:opacity-100">
                            <img src={Mekorot} alt="client" />
                        </div>
                        <div style={{...clientImage, paddingTop: '2rem'}} className="overflow-hidden p-3 flex justify-center transition-all hover:opacity-100">
                            <img src={PCB} alt="client" />
                        </div>
                        <div style={{...clientImage, paddingTop: '2rem'}} className="overflow-hidden p-3 flex justify-center transition-all hover:opacity-100">
                            <img src={Moog} alt="client" />
                        </div>
                        <div style={{...clientImage, paddingTop: '2rem'}} className="overflow-hidden p-3 flex justify-center transition-all hover:opacity-100">
                            <img src={Nilit} alt="client" />
                        </div>
                        <div style={{...clientImage, paddingTop: '2rem'}} className="overflow-hidden p-3 flex justify-center transition-all hover:opacity-100">
                            <img src={sap} alt="client" />
                        </div>
                    </div>
                </div>

                {/* Blog Subjects Section */}
                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <h2 className="my-2 text-center text-3xl text-custom-color2 uppercase font-bold">Blog Subjects</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-green-900'></div>
                    </div>
                    <div className="mt-8 grid gap-4">
                        {blogSubjects.map((subject, index) => (
                            <div key={index} className="my-4 p-4 border rounded bg-white shadow">
                                <h3 className="text-2xl font-bold">{subject.title}</h3>
                                <p className="mt-2">{subject.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Clients;
