import React, { useEffect, useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import BlogFioriImage from '../images/BlogFiori.png';
import { useTranslation } from 'react-i18next';
import './BlogImg.css'; // Import the CSS file

const BlogFiori = () => {
  const [t, i18n] = useTranslation('global');

  // Get current language from i18n
  const [isRTL, setIsRTL] = useState(i18n.language === 'he');

  useEffect(() => {
    // Sync `isRTL` with the current language
    const currentLang = i18n.language;
    setIsRTL(currentLang === 'he');

    // Update document direction
    document.documentElement.setAttribute('dir', currentLang === 'he' ? 'rtl' : 'ltr');
  }, [i18n.language]);

  return (
    <>
      <div>
        <NavBar />
      </div>
      <main
        style={{
          margin: '180px auto',
          fontFamily: 'Arial, sans-serif',
          lineHeight: '1.8',
          textAlign: isRTL ? 'right' : 'left', // Dynamic text alignment
          direction: isRTL ? 'rtl' : 'ltr', // Dynamic text direction
          maxWidth: '1500px',
          padding: '0 20px',
        }}
      >
        <div
          style={{
            width: '100%',
            marginBottom: '30px',
            display: 'flex', // Use flexbox for centering
            justifyContent: 'center', // Horizontally center the image
          }}
        >
          <img
            src={BlogFioriImage}
            alt={t("blogs.blogFiori.imageAlt")}
             className="responsive-image"
            style={{
              width: '50%',
              height: '22rem',
              borderRadius: '8px',
            }}
          />
        </div>
        <article>
          <h1
            style={{
              color: '#68cf24',
              fontSize: '3rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
              textAlign: isRTL ? 'right' : 'left', // Align header text
            }}
          >
            {t("blogs.blogFiori.header1")}
          </h1>
          <p
            style={{
              fontSize: '1.5rem',
              textAlign: isRTL ? 'right' : 'left', // Align paragraph text
            }}
          >
            {t("blogs.blogFiori.IntroParagraph")}
          </p>

          <h2
            style={{
              color: '#68cf24',
              fontSize: '2.5rem',
              marginTop: '2rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
              textAlign: isRTL ? 'right' : 'left', // Align header text
            }}
          >
            {t("blogs.blogFiori.header2")}
          </h2>
          <ul
            style={{
              fontSize: '1.5rem',
              listStyle: 'none',
              padding: '0',
              textAlign: isRTL ? 'right' : 'left', // Align list text
            }}
          >
            <li>{t("blogs.blogFiori.feature1")}</li>
            <li>{t("blogs.blogFiori.feature2")}</li>
            <li>{t("blogs.blogFiori.feature3")}</li>
            <li>{t("blogs.blogFiori.feature4")}</li>
          </ul>

          <h2
            style={{
              color: '#68cf24',
              fontSize: '2.5rem',
              marginTop: '2rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
              textAlign: isRTL ? 'right' : 'left', // Align header text
            }}
          >
            {t("blogs.blogFiori.header3")}
          </h2>
          <p
            style={{
              fontSize: '1.5rem',
              textAlign: isRTL ? 'right' : 'left', // Align paragraph text
            }}
          >
            {t("blogs.blogFiori.benefitsIntro")}
          </p>
          <ol
            style={{
              fontSize: '1.5rem',
              listStylePosition: isRTL ? 'inside' : 'outside',
              textAlign: isRTL ? 'right' : 'left', // Align list text
            }}
          >
            <li>{t("blogs.blogFiori.benefit1")}</li>
            <li>{t("blogs.blogFiori.benefit2")}</li>
            <li>{t("blogs.blogFiori.benefit3")}</li>
            <li>{t("blogs.blogFiori.benefit4")}</li>
          </ol>

          <h2
            style={{
              color: '#68cf24',
              fontSize: '2.5rem',
              marginTop: '2rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
              textAlign: isRTL ? 'right' : 'left', // Align header text
            }}
          >
            {t("blogs.blogFiori.header4")}
          </h2>
          <p
            style={{
              fontSize: '1.5rem',
              textAlign: isRTL ? 'right' : 'left', // Align paragraph text
            }}
          >
            {t("blogs.blogFiori.industry4Paragraph")}
          </p>

          <h2
            style={{
              color: '#68cf24',
              fontSize: '2.5rem',
              marginTop: '2rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
              textAlign: isRTL ? 'right' : 'left', // Align header text
            }}
          >
            {t("blogs.blogFiori.header5")}
          </h2>
          <p
            style={{
              fontSize: '1.5rem',
              textAlign: isRTL ? 'right' : 'left', // Align paragraph text
            }}
          >
            {t("blogs.blogFiori.conclusionParagraph")}
          </p>
        </article>
      </main>
      <Footer />
    </>
  );
};

export default BlogFiori;
