import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import AINoCode from '../images/AINoCode.png';
import { useTranslation } from 'react-i18next';
import './BlogImg.css'; // Import the CSS file
import './BlogLowCode.css'; // Import the CSS file


const BlogAI = () => {
  const [t, i18n] = useTranslation('global');

  // Determine if the current language is RTL
  const isRTL = i18n.language === 'he';

  useEffect(() => {
    // Ensure the <html> element's direction matches the language
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  }, [isRTL]);

  return (
    <>
      <div>
        <NavBar />
      </div>
      <main
        style={{
          margin: '180px auto',
          fontFamily: 'Arial, sans-serif',
          lineHeight: '1.8',
          textAlign: isRTL ? 'right' : 'left', // Dynamic text alignment
          direction: isRTL ? 'rtl' : 'ltr', // Dynamic text direction
          maxWidth: '1500px',
          padding: '0 60px',
        }}
      >
        <div
          style={{
            width: '100%',
            marginBottom: '30px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
  src={AINoCode}
  alt={t("blogs.blogNocodeAI.header1")}
  className="responsive-image"
  style={{
    width: '40%',
    height: '22rem',
    borderRadius: '8px',
  }}
/>

        </div>
        <article>
          <h1
            style={{
              color: '#68cf24',
              fontSize: '3rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
            }}
          >
            {t("blogs.blogNocodeAI.header1")}
          </h1>
          <p
            style={{
              fontSize: '1.5rem',
            }}
          >
            {t("blogs.blogNocodeAI.IntroParagraph")}
          </p>

          <h2
            style={{
              color: '#68cf24',
              fontSize: '2.5rem',
              marginTop: '2rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
            }}
          >
            {t("blogs.blogNocodeAI.header2")}
          </h2>
          <ul
  style={{
    fontSize: '1.5rem',
    listStyle: 'none',
    padding: '0',
  }}
>
  {/* Feature Sub */}
  <li
    style={{
      marginLeft: isRTL ? '0' : '60px', // Indent for LTR
      marginRight: isRTL ? '60px' : '0', // Indent for RTL
      fontWeight: 'bold',
    }}
  >
    {t("blogs.blogNocodeAI.featureSub")}
  </li>

  {/* Feature 2 */}
  <li
    style={{
      marginLeft: isRTL ? '0' : '120px', // Indent for LTR
      marginRight: isRTL ? '120px' : '0', // Indent for RTL
    }}
  >
    <span style={{ fontWeight: 'bold' }}>
      {t("blogs.blogNocodeAI.feature2BOLD")}
    </span>
    {' '}
    {t("blogs.blogNocodeAI.feature2")}
  </li>

  {/* Feature 3 */}
  <li
    style={{
      marginLeft: isRTL ? '0' : '120px', // Indent for LTR
      marginRight: isRTL ? '120px' : '0', // Indent for RTL
    }}
  >
    <span style={{ fontWeight: 'bold' }}>
      {t("blogs.blogNocodeAI.feature3BOLD")}
    </span>
    {' '}
    {t("blogs.blogNocodeAI.feature3")}
  </li>

  {/* Feature Sub 2 */}
  <li
    style={{
      marginLeft: isRTL ? '0' : '60px', // Indent for LTR
      marginRight: isRTL ? '60px' : '0', // Indent for RTL
      fontWeight: 'bold',
    }}
  >
    {t("blogs.blogNocodeAI.featureSub2")}
  </li>

  {/* Feature 4 */}
  <li
    style={{
      marginLeft: isRTL ? '0' : '120px', // Indent for LTR
      marginRight: isRTL ? '120px' : '0', // Indent for RTL
    }}
  >
    <span style={{ fontWeight: 'bold' }}>
      {t("blogs.blogNocodeAI.feature4BOLD")}
    </span>
    {' '}
    {t("blogs.blogNocodeAI.feature4")}
  </li>

  {/* Feature 5 */}
  <li
    style={{
      marginLeft: isRTL ? '0' : '120px', // Indent for LTR
      marginRight: isRTL ? '120px' : '0', // Indent for RTL
    }}
  >
    <span style={{ fontWeight: 'bold' }}>
      {t("blogs.blogNocodeAI.feature5BOLD")}
    </span>
    {' '}
    {t("blogs.blogNocodeAI.feature5")}
  </li>
</ul>



          <h2
            style={{
              color: '#68cf24',
              fontSize: '2.5rem',
              marginTop: '2rem',
              marginBottom: '1rem',
              fontWeight: 'bold'
            }}
          >
            {t("blogs.blogNocodeAI.header3")}
          </h2>
          <p
            style={{
              fontSize: '1.5rem',
              marginLeft: isRTL ? '0' : '60px', // Indent for LTR
              marginRight: isRTL ? '60px' : '0', // Indent for RTL
              fontWeight: 'bold'
            }}
          >
            {t("blogs.blogNocodeAI.AIsub")}
          </p>
          <p
  style={{
    fontSize: '1.5rem',
    marginLeft: isRTL ? '0' : '120px', // Indent for LTR
    marginRight: isRTL ? '120px' : '0', // Indent for RTL
  }}
>
  <span style={{ fontWeight: 'bold' }}>
    {t("blogs.blogNocodeAI.AifirstdotBOLD")}
  </span>
  {' '}
  {t("blogs.blogNocodeAI.Aifirstdot")}
</p>

          <p
            style={{
              fontSize: '1.5rem',
              marginLeft: isRTL ? '0' : '120px', // Indent for LTR
              marginRight: isRTL ? '120px' : '0', // Indent for RTL
               fontWeight: 'bold'
            }}
          >
            {t("blogs.blogNocodeAI.Aisub2")}
          </p>
          <p
  style={{
    fontSize: '1.5rem',
    marginLeft: isRTL ? '0' : '140px', // Indent for LTR
    marginRight: isRTL ? '140px' : '0', // Indent for RTL
  }}
>
  <span style={{ fontWeight: 'bold' }}>
    {t("blogs.blogNocodeAI.AiSecondDotBOLD")}
  </span>
  {' '}
  {t("blogs.blogNocodeAI.AiSecondDot")}
</p>
<p
  style={{
    fontSize: '1.5rem',
    marginLeft: isRTL ? '0' : '140px', // Indent for LTR
    marginRight: isRTL ? '140px' : '0', // Indent for RTL
  }}
>
  <span style={{ fontWeight: 'bold' }}>
    {t("blogs.blogNocodeAI.AiThirdDotBOLD")}
  </span>
  {' '}
  {t("blogs.blogNocodeAI.AiThirdDot")}
</p>


          <h2
            style={{
              color: '#68cf24',
              fontSize: '2.5rem',
              marginTop: '2rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
            }}
          >
            {t("blogs.blogNocodeAI.header4")}
          </h2>
          <p
            style={{
              fontSize: '1.5rem',
            }}
          >
            {t("blogs.blogNocodeAI.dataModelIntro")}
          </p>
          <p
  style={{
    fontSize: '1.5rem',
    marginLeft: isRTL ? '0' : '60px', // Indent for LTR
    marginRight: isRTL ? '60px' : '0', // Indent for RTL
  }}
>
  <span style={{ fontWeight: 'bold' }}>
    {t("blogs.blogNocodeAI.dataModel1BOLD")}
  </span>
  {' '}
  {t("blogs.blogNocodeAI.dataModel1")}
</p>

<p
  style={{
    fontSize: '1.5rem',
    marginLeft: isRTL ? '0' : '60px', // Indent for LTR
    marginRight: isRTL ? '60px' : '0', // Indent for RTL
  }}
>
  <span style={{ fontWeight: 'bold' }}>
    {t("blogs.blogNocodeAI.dataModel2BOLD")}
  </span>
  {' '}
  {t("blogs.blogNocodeAI.dataModel2")}
</p>

<p
  style={{
    fontSize: '1.5rem',
    marginLeft: isRTL ? '0' : '60px', // Indent for LTR
    marginRight: isRTL ? '60px' : '0', // Indent for RTL
  }}
>
  <span style={{ fontWeight: 'bold' }}>
    {t("blogs.blogNocodeAI.dataModel3BOLD")}
  </span>
  {' '}
  {t("blogs.blogNocodeAI.dataModel3")}
</p>


          <h2
  style={{
    color: '#68cf24',
    fontSize: '2.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    fontWeight: 'bold',
  }}
>
  {t("blogs.blogNocodeAI.header5")}
</h2>
<p
  style={{
    fontSize: '1.5rem',
  }}
>
  {t("blogs.blogNocodeAI.conclusion")}
</p>
<p
  style={{
    fontSize: '1.5rem',
    marginLeft: isRTL ? '0' : '60px', // Indent for LTR
    marginRight: isRTL ? '60px' : '0', // Indent for RTL
  }}
>
  <span style={{ fontWeight: 'bold' }}>
    {t("blogs.blogNocodeAI.conclusionfeature1BOLD")}
  </span>
  {' '}
  {t("blogs.blogNocodeAI.conclusionfeature1")}
</p>

<p
  style={{
    fontSize: '1.5rem',
    marginLeft: isRTL ? '0' : '60px', // Indent for LTR
    marginRight: isRTL ? '60px' : '0', // Indent for RTL
  }}
>
  <span style={{ fontWeight: 'bold' }}>
    {t("blogs.blogNocodeAI.conclusionfeature2BOLD")}
  </span>
  {' '}
  {t("blogs.blogNocodeAI.conclusionfeature2")}
</p>

<p
  style={{
    fontSize: '1.5rem',
    marginLeft: isRTL ? '0' : '60px', // Indent for LTR
    marginRight: isRTL ? '60px' : '0', // Indent for RTL
  }}
>
  <span style={{ fontWeight: 'bold' }}>
    {t("blogs.blogNocodeAI.conclusionfeature3BOLD")}
  </span>
  {' '}
  {t("blogs.blogNocodeAI.conclusionfeature3")}
</p>


        </article>
      </main>
      <Footer />
    </>
  );
};

export default BlogAI;
