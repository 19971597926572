import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import blog1 from '../images/blog1.jpg';
import blog2 from '../images/blog2.jpeg';
import blog3 from '../images/blog3.jpeg';
import blog4 from '../images/BlogITvsOT.avif';
import Yuval from '../images/Yuval.png';
import Yaniv from '../images/Yaniv.png';
import AINoCode from '../images/AINoCode.png';

import { Link } from 'react-router-dom';

const Blog = () => {
  const [t, i18n] = useTranslation('global');
  const [isLoading, setIsLoading] = useState(true);

  const initialLanguage = localStorage.getItem('language') || 'en';
  const initialIsRTL = initialLanguage === 'he';

  const [isRTL, setIsRTL] = useState(initialIsRTL);

  useEffect(() => {
    const lang = isRTL ? 'he' : 'en';
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);

    if (isRTL) {
      document.body.classList.remove('dir-ltr');
      document.body.classList.add('dir-rtl');
    } else {
      document.body.classList.remove('dir-rtl');
      document.body.classList.add('dir-ltr');
    }

    setIsLoading(false);
  }, [isRTL, i18n]);

  const blogs = [
    {
      id: 1,
      image: blog1,
      title: t('blogs.blogMaimPage.MESManufacturing'),
      description: t('blogs.blogMaimPage.MESManufacturingSUB'),
      link: '/BlogMesManufacturing', 
      date: '2024-11-10',
      author: t('blogs.blogMaimPage.Yuval'),
      authorImage: Yuval,
    },
    {
      id: 2,
      image: blog2,
      title: t('blogs.blogMaimPage.SAPFiori'),
      description: t('blogs.blogMaimPage.SAPFioriSUB'),
      link: '/BlogSapFiori', 
      date: '2024-11-12',
      author: t('blogs.blogMaimPage.Yaniv'),
      authorImage: Yaniv,
    },
    {
      id: 3,
      image: blog3,
      title: t('blogs.blogMaimPage.SAPAI'),
      description: t('blogs.blogMaimPage.SAPAISUB'),
      link: '/BlogSapAI', 
      date: '2024-11-15',
      author: t('blogs.blogMaimPage.Yaniv'),
      authorImage: Yaniv,
    },
    {
      id: 4,
      image: blog4,
      title: t('blogs.blogMaimPage.ITvsOT'),
      description: t('blogs.blogMaimPage.ITvsOTSUB'),
      link: '/BlogITvsOT', 
      date: '2024-11-15',
      author: t('blogs.blogMaimPage.Yuval'),
      authorImage: Yuval,
    },
    {
      id: 5,
      image: AINoCode,
      title: t('blogs.blogMaimPage.LowCodeApplicationDevelopment'),
      description: t('blogs.blogMaimPage.LowCodeApplicationDevelopmentSUB'),
      link: '/LowCodeWithAi', 
      date: '2024-12-05',
      author: t('blogs.blogMaimPage.Yuval'),
      authorImage: Yuval,
    },
  ];

  return (
    <>
      <div>
        <NavBar />
      </div>

      <div
        id="blog-section"
        className="flex items-top justify-center min-h-screen bg-gray-100"
        style={{ marginTop: '125px' }}
      >
        <div className="container mx-auto px-6 lg:px-20" style={{ marginTop: '80px' }}>
          <h2 className="text-3xl font-bold text-center mb-10">
            {t('blogs.blogMaimPage.mainHeader')}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {blogs.map((blog) => (
              <div
                key={blog.id}
                className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
              >
                <img src={blog.image} alt={blog.title} className="w-full h-48 object-cover" />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-4">{blog.title}</h3>
                  <p className="text-gray-600 mb-6">{blog.description}</p>
                  <div className="flex justify-between items-center">
                    <Link
                      to={blog.link}
                      style={{
                        backgroundColor: '#68cf24',
                        color: '#fff',
                      }}
                      className={`inline-flex items-center justify-center w-full px-4 py-2 my-2 text-base shadow-lg rounded-lg sm:w-auto sm:mb-0`}
                    >
                      {t('blogs.blogMaimPage.ReadMore')}
                    </Link>

                    <div className="flex items-center">
                      <img
                        src={blog.authorImage}
                        alt={blog.author}
                        className="w-8 h-8 rounded-full"
                        style={{
                          marginRight: isRTL ? '20px' : '10px', // Add more margin for Hebrew
                        }}
                      />
                      <div>
                        <p className="text-sm text-gray-700">{blog.author}</p>
                        <p className="text-xs text-gray-500">{blog.date}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full bg-white py-6 bg-gray-200">
        <Footer />
      </div>
    </>
  );
};

export default Blog;
